export enum DOMAIN_NUMBER {
    LOCALHOST= 0,
    FLYHIGHMEDIA = 1,
    FAMEPOP=2
}

export const DOMAINS: Record<DOMAIN_NUMBER, string> = {
    [DOMAIN_NUMBER.FAMEPOP]: 'https://www.app.famepop.co/dashboard',
    [DOMAIN_NUMBER.FLYHIGHMEDIA]:
        'https://www.flyhighmedia.co',
    [DOMAIN_NUMBER.LOCALHOST]: 'http://localhost:3000/dashboard',
};
