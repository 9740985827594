import { DOMAIN_NUMBER } from "@constants/DOMAINS";

export const numberWithCommas: (value: string | number) => string = (value) =>
    new Intl.NumberFormat().format(Number(value));

export const formatNumber: (value: number | string) => string = (value) => {
    if (typeof value === 'string') {
        return parseFloat(value).toFixed(2);
    }
    return value.toFixed(2);
};

export const formatCurrency: (value: number) => string = (value) => {
    if (value === 0) return value.toString();
    return value?.toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
};

export const getDomainNumber = (domain?: string) => {
    if (domain.includes('famepop.co')) {
        return DOMAIN_NUMBER.FAMEPOP
    }
    if (domain.includes('localhost')) {
        return DOMAIN_NUMBER.LOCALHOST
    }
    if (domain.includes('flyhighmedia.co')) {
        return DOMAIN_NUMBER.FLYHIGHMEDIA
    }
    return DOMAIN_NUMBER.FLYHIGHMEDIA
};
